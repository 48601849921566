<template>
  <v-container align-center justify-center>
    <v-layout row wrap justify-center>
      <v-img src="@/assets/images/logo.jpg" max-height="190" max-width="150">
      </v-img>
    </v-layout>
    <v-layout row wrap>
      <v-card class="mx-auto" color="green" dark max-width="800">
        <v-card-title>
          <v-icon large left>fas fa-check-circle</v-icon>
          <span class="title">Registered Order</span>
        </v-card-title>

        <v-card-text class="light headline font-weight-bold text-xs-center">
          <p>YOUR ORDER HAS BEEN REGISTRED SUCCESSFULLY</p>
        </v-card-text>

        <v-card-actions class="align-center justify-center">
          <v-btn color="success" dark @click="redirect">
            OK
            <v-icon right>fas fa-check-circle</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
const timeout = 1000 * 15; // 10 segundos
let timer = null;
export default {
  name: "policies",
  data() {
    return {};
  },
  created() {
    timer = setTimeout(() => this.redirect(), timeout);
  },
  methods: {
    redirect() {
      clearTimeout(timer);

      this.$router.push({
        name: "actionType",
      });
    },
  },
};
</script>

<style>
.action {
  height: 90px;
  font-size: 1.3em;
}

.light {
  color: white !important;
}
</style>
